<template>
  <div>
    <main-template>
        <page-title title="Download Manufacturer Report"></page-title>
        <div class="container mx-auto px-4">
            <manufacturer-orders-report-form v-if="action" :action="action"></manufacturer-orders-report-form>
        </div>
    </main-template>
  </div>
</template>

<script>
import ActionHelper from '../mixins/Siren/ActionHelper';
import MainTemplate from "../templates/MainTemplate";
import ManufacturerOrdersReportForm from "@/components/CustomForms/ManufacturerOrdersReportForm";
import { mapGetters } from "vuex";
import PageTitle from '../common/PageTitle';
import Siren from 'super-siren';
let follow = Siren.Helper.follow;
export default {
  mixins: [ActionHelper],
  name: "Reports",
  components: {
    PageTitle,
    MainTemplate,
      ManufacturerOrdersReportForm
  },
  data() {
    return {
      action: null
    }
  },
  computed: {
    ...mapGetters({
      accessToken: "getAccessToken"
    })
  },
  mounted () {
    Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
      Siren.get(process.env.VUE_APP_API_URL + "/api/")
        .then(follow("orders"))
        .then(res => {
          let siren = res.body;
          this.action = siren.actions.filter(action => action.name === 'get-manufacturer-report').get('get-manufacturer-report');
        }, this);

  },
};
</script>
